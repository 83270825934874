/**
 * Парсинг строки в URL адрес
 * @param href Строка с адресом
 * @returns {Object} Объект со свойствами URLUtils (https://developer.mozilla.org/en-US/docs/Web/API/HTMLHyperlinkElementUtils)
 */

export default function parseUrl (href) {
  if (!href) return null
  const elem = document.createElement('a')
  elem.href = href

  let queryParams = {}
  if (elem.search) {
    const queryStrings = elem.search.substring(1)
    const params = queryStrings.split('&')
    for (let i = 0; i < params.length; i++) {
      const pair = params[i].split('=')
      queryParams[pair[0]] = decodeURIComponent(pair[1])
    }
  } else {
    queryParams = null
  }

  return {
    href: elem.href,
    protocol: elem.protocol,
    host: elem.host,
    hostname: elem.hostname,
    port: elem.port,
    pathname: elem.pathname,
    hash: elem.hash,
    search: elem.search,
    searchObject: queryParams,
    username: elem.username,
    password: elem.password,
    origin: elem.origin ? elem.origin : elem.protocol + '//' + elem.host
  }
}

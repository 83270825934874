<template>
  <loading-block v-if="loading" />
  <message-wrapper v-else-if="token">
    <template v-slot:title>Подтверждение почты</template>
    <template v-slot:text>Ваша почта успешно подтверждена</template>
    <template v-slot:footer>
      <a href="/" class="button">На главную</a>
    </template>
  </message-wrapper>
  <message-wrapper v-else>
    <template v-slot:title>Проверьте почту</template>
    <template v-slot:text>Ваша почта не&#160;подтверждена. На&#160;Вашу электронную почту отправлено письмо с&#160;инструкцией для&#160;подтверждения почты.</template>
    <template v-slot:description>Если не&#160;можете его найти, посмотрите письмо в&#160;папке Спам.</template>
  </message-wrapper>
</template>

<script>
import MessageWrapper from '@/components/common/MessageWrapper'
import LoadingBlock from '@/components/common/LoadingBlock'
import parseUrl from '@/assets/helpers/parse_url'
import Cookies from 'js-cookie'
import { mapActions } from 'vuex'

export default {
  name: 'EmailConfirm',
  components: {
    MessageWrapper,
    LoadingBlock
  },
  data () {
    return {
      loading: false,
      token: null
    }
  },
  beforeMount () {
    if (parseUrl(window.location).searchObject && parseUrl(window.location).searchObject.token) {
      this.token = parseUrl(window.location).searchObject.token
      this.loading = true
      this.$axios.post(this.getPathRequest('email_confirm'), { email_confirm_token: this.token })
        .then(() => {
          this.loading = false
        })
        .catch(error => {
          this.openModal('ErrorModal', {
            title: 'Ошибка подтверждения почты',
            text: error.response.data.message
              ? error.response.data.message
              : 'Во время подтверждения почты произошла ошибка. Пожалуйста, попробуйте снова или обратитесь к нам в тех. поддержку.'
          })
        })
    }
  },
  mounted () {
    const refData = Cookies.get('ref_data')
    if (refData) {
      Cookies.remove('ref_data', { domain: '.nalozhka.ru' })
      this.setUser(JSON.parse(decodeURIComponent(refData)))
    }
  },
  methods: {
    ...mapActions([
      'setUser'
    ])
  }
}
</script>
